html, body, #root {
  height: 100%;
}

.header-title {
  size: lg;
  color: white;
}

.hero-section {
  background-color: black;
}

.hero-text {
  color: white;
}

.footer-section {
  background-color: black;
}
.footer-text {
  color: white;
}
.footer-link {
  color: #2B9AF3;
}
